export default {
    SAlES_ORDERS: {
        ENTITY_NAME:"sales_orders",
        REASONS: {
            APPLY_DISCOUNT: "discount"
        }
    },
    
    SALES_ORDER_PAYMENTS: {
        ENTITY_NAME: "sales_order_payments",
        REASONS: {
            CANCEL_PAYMENT: "cancel_payment"
        }
    }
}