import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

/**
 * Mapeamento das origens de processos.
 * @type {Object<number, string>}
 */
const processOriginMap = {
    1: "Abertura de Caixa",
    2: "Fechamento de Caixa",
    3: "Recebimento de Cliente",
    4: "Depósito Bancário",
    5: "Subsídio Médico",
    6: "Transferencia entre Caixas",
    7: "Máquina de Café",
    8: "Cancelamento de Pagamento do Cliente",
    9: "Pagamento de Repasse",
    10: "Cancelamento Pagamento de Repasse",
    11: "Gastos Diversos",
};

/**
 * Mapeamento dos tipos de movimentação de caixa.
 * @type {Object<string, string>}
 */
const typeMap = {
    I: "Entrada",
    O: "Saída",
};

/**
 * Gera as colunas para a tabela de Movimentações de Caixa.
 * 
 * @returns {Array<Object>} - Array de objetos representando as colunas da tabela.
 */
const CashMovesTableColumns = () => [
    {
        dataField: "type",
        text: "Tipo",
        formatter: (cellContent, row) => typeMap[row.type] || "Desconhecido",
    },
    {
        dataField: "unit_id",
        text: "Unidade",
        formatter: (cellContent, row) => row.unit.name || "N/A",
    },
    {
        dataField: "amount",
        text: "Valor",
        formatter: (cellContent, row) => `R$ ${(row?.amount / 100)?.toFixed(2)}`,
    },
    {
        dataField: "process_id",
        text: "Processo",
        formatter: (cellContent, row) => processOriginMap[row.process_id] || "N/A",
    },
    {
        dataField: "description",
        text: "Descrição",
        formatter: (cellContent, row) => row.description || "N/A",
    },
    {
        dataField: "professional_id",
        text: "Profissional",
        formatter: (cellContent, row) => 
            row.professional 
                ? `${row.professional.name}<${row.professional_id}>`
                : "N/A",
    },
    {
        dataField: "financial_account_id",
        text: "Conta Financeira",
        formatter: (cellContent, row) => 
            row.financial_account 
                ? `${row.financial_account.name}<${row.financial_account_id}>`
                : "N/A",
    },
    {
        dataField: "created_user_id",
        text: "Usuário Criador",
        formatter: (cellContent, row) => row.created_user.name || "N/A",
    },
    {
        dataField: "created_at",
        text: "Criado em",
        formatter: (cellContent, row) => new Date(row.created_at).toLocaleDateString("pt-BR"),
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (cellContent, row) => (
            <Link to={`/controle-caixa/${row.id}/editar`}>
                <i className="fas fa-edit font-size-24 mr-3" id={"edittooltip" + row.id} />
                <UncontrolledTooltip placement="top" target={"edittooltip" + row.id}>
                    Editar
                </UncontrolledTooltip>
            </Link>
        ),
    },
];

export default CashMovesTableColumns;

