import React, { useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import AsyncSelect from "react-select/async";
import { Button, Col, Row, Label, Input } from "reactstrap";
import debounce from "debounce-promise";
import useCashControl from "../../../../services/vitta-core/cash-control";
import CashMovesTableColumns from "./CashMovesTableColumns";
import { search as searchUnits } from "services/vitta-core/unit";

// Debounce utility function for async requests
const debounceOptions = (loadOptions) => debounce(loadOptions, 600);

/**
 * Componente de Tabela de Movimentações de Caixa.
 * 
 * @param {Object} props - Propriedades do componente.
 * @param {React.Ref} ref - Referência passada para o componente pai para permitir o controle externo.
 */
const CashMovesDataTable = forwardRef((props, ref) => {
    const { cashControl, setCashControl, searchCashMoves, findCashControlUsers, searchUsers } = useCashControl();

    /**
     * Função para buscar movimentações financeiras com filtros.
     * 
     * @param {Object} filter - Filtros a serem aplicados na busca.
     * @param {number} page - Página atual da busca.
     */
    const search = async (filter, page) => {
        let sizePerPage = 10;

        try {
            let data = await searchCashMoves(filter, page, sizePerPage);
            setCashControl({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages * sizePerPage,
                custom: true,
                data: data.data,
            });
        } catch (e) {
            console.error("Erro ao buscar registros financeiros:", e);
        }
    };

    /**
     * Função para preparar os filtros de busca e executar a pesquisa.
     * 
     * @param {number} page - Página da busca.
     */
    const searchCashMovesWithFilters = (page = 1) => {
        let searchObj = {};
        let type = inputType.current?.select?.state?.value?.value;
        let unit_id = inputUnit.current?.select?.state?.value?.value;
        let process_id = inputProcess.current?.select?.state?.value?.value;
        let created_user_id = inputCreatedUser.current?.select?.state?.value?.value;
        let date_start = inputStartDate.current?.value;
        let date_end = inputEndDate.current?.value;

        if (type) searchObj.type = type;
        if (unit_id) searchObj.unit_id = unit_id;
        if (process_id) searchObj.process_id = process_id;
        if (created_user_id) searchObj.created_user_id = created_user_id;
        if (date_start) searchObj.date_start = date_start;
        if (date_end) searchObj.date_end = date_end;

        search(searchObj, page);
    };

    // References for filters
    const inputType = useRef();
    const inputUnit = useRef();
    const inputProcess = useRef();
    const inputCreatedUser = useRef();
    const inputStartDate = useRef();
    const inputEndDate = useRef();

    /**
     * Função para carregar as opções de tipos de movimentação.
     * 
     * @returns {Array<Object>} - Lista de opções para o select.
     */
    const loadTypeOptions = async () => {
        return [
            { label: "Entrada", value: "I" },
            { label: "Saída", value: "O" },
        ];
    };

    /**
     * Função para carregar as opções de unidades.
     * 
     * @param {string} inputValue - Termo digitado no campo de busca.
     * @returns {Promise<Array>} - Lista de unidades.
     */
    const loadUnitOptions = async (inputValue) => {
        const response = await searchUnits({ term: inputValue }, 1, 20);
        return response.data.map(unit => ({
            label: unit.name,
            value: unit.id
        }));
    };

    const processOriginMap = {
        1: "Abertura de Caixa",
        2: "Fechamento de Caixa",
        // 3: "Recebimento de Cliente",
        4: "Depósito Bancário",
        5: "Subsídio Médico",
        6: "Transferencia entre Caixas",
        7: "Máquina de Café",
        // 8: "Cancelamento de Pagamento do Cliente",
        // 9: "Pagamento de Repasse",
        // 10: "Cancelamento Pagamento de Repasse",
        11: "Gastos Diversos",
    };

    /**
     * Função para carregar as opções de processos de origem.
     * 
     * @returns {Array<Object>} - Lista de opções de processos.
     */
    const loadProcessOptions = async () => {
        const processOptions = Object.keys(processOriginMap).map((key) => ({
            label: processOriginMap[key],
            value: key,
        }));
        return processOptions;
    };

    /**
     * Função para carregar as opções de usuários criadores.
     * 
     * @param {string} inputValue - Termo digitado no campo de busca.
     * @returns {Promise<Array>} - Lista de usuários.
     */
    const loadCreatedUserOptions = async (inputValue) => {
        const response = await findCashControlUsers();
        
        return response.map(user => ({
            label: `${user.id} - ${user.name}`, 
            value: user.id 
        }));
    };

    const debounceTypeOptions = debounceOptions(loadTypeOptions);
    const debounceUnitOptions = debounceOptions(loadUnitOptions);
    const debounceProcessOptions = debounceOptions(loadProcessOptions);
    const debounceCreatedUserOptions = debounceOptions(loadCreatedUserOptions);

    useEffect(() => {
        searchCashMovesWithFilters();
    }, []);

    /**
     * Função para lidar com a mudança na paginação da tabela.
     * 
     * @param {string} type - Tipo de evento da tabela.
     * @param {Object} param1 - Parâmetros da tabela.
     */
    const handleTableChange = (type, { page }) => {
        if (type === "pagination") {
            searchCashMovesWithFilters(page);
        }
    };

    // Exposing a method to refresh the table from parent components
    useImperativeHandle(ref, () => ({
        refreshTable: () => {
            searchCashMovesWithFilters();
        },
    }));

    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="4">
                    <Label>Tipo de Movimento</Label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={debounceTypeOptions}
                        placeholder="Selecione o tipo"
                        ref={inputType}
                        isClearable={true}
                    />
                </Col>
                <Col sm="4">
                    <Label>Unidade</Label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={debounceUnitOptions}
                        placeholder="Selecione a unidade"
                        ref={inputUnit}
                        isClearable={true}
                    />
                </Col>
                <Col sm="4">
                    <Label>Processo</Label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={debounceProcessOptions}
                        placeholder="Selecione o processo"
                        ref={inputProcess}
                        isClearable={true}
                    />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col sm="3">
                    <Label>Usuário Criador</Label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={debounceCreatedUserOptions}
                        placeholder="Selecione o usuário"
                        ref={inputCreatedUser}
                        isClearable={true}
                    />
                </Col>
                <Col sm="4">
                    <Label>Data de:</Label>
                    <Input type="date" innerRef={inputStartDate}></Input>
                </Col>
                <Col sm="4">
                    <Label>Data Até:</Label>
                    <Input type="date" innerRef={inputEndDate}></Input>
                </Col>
                <Col sm="1">
                    <Button color="primary" className="mt-4" onClick={() => searchCashMovesWithFilters()}>
                        Buscar
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col xl="12">
                    <PaginationProvider pagination={paginationFactory(cashControl)}>
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider keyField="id" data={cashControl.data || []} columns={CashMovesTableColumns()} bootstrap4 search>
                                {toolkitProps => (
                                    <React.Fragment>
                                        <div style={{ overflowX: 'auto' }}>
                                            <BootstrapTable
                                                responsive
                                                remote
                                                bordered={false}
                                                striped={false}
                                                classes={"table table-centered table-nowrap"}
                                                headerWrapperClasses={"table-light"}
                                                {...toolkitProps.baseProps}
                                                onTableChange={handleTableChange}
                                                {...paginationTableProps}
                                            />
                                        </div>
                                        <Row className="align-items-md-center mt-30">
                                            <Col className="pagination pagination-rounded justify-content-center mt-4 inner-custom-pagination">
                                                <PaginationListStandalone {...paginationProps} />
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        )}
                    </PaginationProvider>
                </Col>
            </Row>
        </React.Fragment>
    );
});

export default CashMovesDataTable;
