import {
    Row,
    Col,
    Button,
    Input,
    CardTitle,
    Label,
    CardHeader,
    Collapse,
    FormGroup,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    UncontrolledDropdown,
    Table,
} from "reactstrap"
import AsyncSelect from "react-select/async"
import React, { useEffect, useState } from "react"
import { FieldArray, FastField } from "formik"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Editor } from "react-draft-wysiwyg"
import { search as searchItems } from "services/vitta-core/item"
import { useDispatch } from "react-redux"
import ModalSaveModels from "./ModalModels/Components/ModalSaveModels"
import ModalPrescriptions from "./ModalModels/Components/Modal"
import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import RenderForm from "pages/Patients/Detail/pages/GeneralAttendance/components/renderForm"
import {
    createEntity,
    deleteEntity,
    createExamItem,
    deleteExamItem,
    updateExamItem,
} from "services/vitta-core/attendance"
import MEDICAL_FORMS_TYPES from "utils/consts/medical-form-types"
import { createModel } from "services/vitta-core/medical-forms-models"
import handleMedicalFormUpdate from "pages/Patients/Detail/pages/GeneralAttendance/Functions/handleMedicalFormUpdate"
import debounce from "debounce-promise"
const InputTextt = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => (
    <Input
        type="text"
        {...props}
        {...field}
        onBlur={e => {
            field.onBlur(e)
            handleMedicalFormUpdate(
                props.attendanceId,
                props.stateSync,
                props.datasyncstate,
                props.entityId,
                field,
                props.lastFormState,
                "MedicalExams"
            )
        }}
    />
)

const AsyncSelectInputt = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
        onBlur={e => {
            field.onBlur(e)
            handleMedicalFormUpdate(
                props.attendanceId,
                props.stateSync,
                props.datasyncstate,
                props.entityId,
                field,
                props.lastFormState,
                "MedicalExams",
                props.itemExam
            )
        }}
    />
)

const InputEditor = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => (
    <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        className="mb-2"
        onEditorStateChange={option => {
            setFieldValue(field.name, option)
        }}
        toolbar={{
            options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "image",
                "remove",
                "history",
            ],
        }}
        onBlur={() => {
            handleMedicalFormUpdate(
                props.attendanceId,
                props.stateSync,
                props.datasyncstate,
                props.entityId,
                field,
                props.lastFormState,
                "MedicalExams"
            )
        }}
        editorState={props.editorState}
        readOnly={props.disabled}
    />
)
const NumberInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => (
    <Input
        type="number"
        {...field}
        {...props}
        // className="mb-3"
        // height="auto"
        //rows={3}
        onBlur={e => {
            field.onBlur(e)
            handleMedicalFormUpdate(
                props.attendanceId,
                props.stateSync,
                props.datasyncstate,
                props.entityId,
                field,
                props.lastFormState,
                "MedicalExams",
                props.itemExam
            )
        }}
    />
)

const lastFormState = {}

const MedicalExams = ({
    items,
    defaultItem,
    datasyncstate,
    attendance_id,
    stateSync,
    patient_id,
    errors,
    touched,
    professional,
    patient,
    unit
}) => {
    const [certificate_id, setCertificateId] = useState(null)
    const [modalPrescriptionData, setModalPrescriptionData] = useState({
        show: false,
        entity_type: null,
        data: null,
    })

    const [modalSaveModels, setModalSaveModels] = useState({
        show: false,
        data: null,
    })
    const [tab, setTab] = useState(0)
    const dispatch = useDispatch()
    function HandleTab(index) {
        setTab(index)

        if (tab == index) {
            setTab(null)
        }
    }
    const loadItemsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let group_item_ids= [];
                group_item_ids = group_item_ids.concat(process.env.REACT_APP_ITEM_EXAM_GROUP_IDS.split(","),process.env.REACT_APP_ITEM_PROCEDIMENT_GROUP_IDS.split(","),process.env.REACT_APP_SURGERY_GROUP_ID)
                
                const statusActive = 1
                let response = await searchItems(
                    {
        
                        term: inputValue,
                        group_id: group_item_ids,
                        status: statusActive
                    },
                    1,
                    25
                )
                
                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label:
                            /* `${element.problem_id ? element.problem_id : "" }  - ` + */ element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsItem = debounce(loadItemsInputOptions, 600)

    let defaultExamValues = {
        //selected: true,
        item_id: null,
        quantity: 1,
        entity: "itemsExam",
    }

    const handleCreate = async data => {
        try {
            stateSync(0)
            let created = null

            let itemValue = {}

            itemValue.entity = "MedicalExams"
            itemValue.attendance_id = attendance_id
            itemValue.title = ""
            itemValue.text_object = ""
            itemValue.itemsExam = []

            if (data) {
                created = await createEntity(data, attendance_id)
            } else {
                created = await createEntity(itemValue, attendance_id)
            }
            itemValue.id = created.id
            datasyncstate(created?.form)
            stateSync(1)
            if (itemValue.id) {
                return itemValue
            }
        } catch (e) {
            console.log(e)
            dispatch(
                showSweetAlertDialog({
                    title: "Ocorreu algum problema ! ",
                    text:
                        "Houve um erro, tente novamente ou contate o suporte.",
                    type: "error",
                    showConfirm: true,
                })
            )
            stateSync(2)
        }
    }
    const handleBG = id => {
        let element = document.getElementById(id)
        element.classList.add("bg-info")
        element.classList.add("text-white")
    }
    const removeBG = id => {
        let element = document.getElementById(id)
        element.classList.remove("bg-info")
        element.classList.remove("text-white")
    }
    const handleDelete = async item => {
        stateSync(0)
        // console.log("id", item)

        await deleteEntity(item.id, "MedicalExam", attendance_id)
        stateSync(1)
    }

    // const handleItemExam = async item =>{
    //     stateSync(0)
    //     const value = {...item}
    //     value.item_id = item.item_id? item.item_id.value : null
    //     let result = await createExamItem(value, attendance_id)
    //     item.id = result.id
    //     stateSync(1)
    // }
 
    const handleCreateExam = async item => {
        stateSync(0)
        let createExam = null
        const defaultValue = { ...defaultExamValues }
        defaultValue.MedicalExamId = item.id
      
        if(item.itemsExam && typeof(item.itemsExam)=='number') defaultValue.item_id = item.itemsExam
        createExam = await createExamItem(defaultValue, attendance_id)
        datasyncstate(createExam?.form)
        stateSync(1)
        defaultValue.id = createExam.id

        if (defaultValue.id && defaultValue.MedicalExamId) {
            return defaultValue
        }   
    }
    

    const handleDeleteExamItem = async entity_id => {
        stateSync(0)
        await deleteExamItem(attendance_id, entity_id)
        stateSync(1)
    }
    const viewForm = data => {
        let content = { ...data, unit: unit, created_at: items?.created_at }
        const showProfessionalSignature = true
        const ShowPatientData = true
        const showPrintButton = true
        if (data?.text_object && data?.text_object != "") {
            content.text = draftToHtml(
                convertToRaw(data?.text_object.getCurrentContent())
            )
        }

        RenderForm({
            showProfessionalSignature,
            ShowPatientData,
            showPrintButton,
            patient,
            professional,
            content,
        })
    }

    return (
        <React.Fragment>
            <CardTitle>Solicitação de Exames, Procedimentos e Cirurgias</CardTitle>
            <FieldArray
                name="items"
                render={arrayHelpers => (
                    <div>
                        {items && items.items.length > 0
                            ? items.items.map((item, index) => (
                                  <Row key={index} className="mb-2 ">
                                      <Col sm="11" className="mt-3">
                                          <CardHeader
                                              style={{
                                                  cursor: "pointer",
                                                  height: "50px",
                                              }}
                                              className="shadow-lg bg-white"
                                              onClick={() => {
                                                  //   setTab(!index)

                                                  HandleTab(index)
                                              }}
                                          >
                                              <Row>
                                                  <Col sm="6" className="mt-1">
                                                      <Label>
                                                          #Solicitação Médica
                                                          - {index ? index : 0}{" "}
                                                      </Label>
                                                  </Col>
                                                  <Col sm="6">
                                                      <div align="end">
                                                          {" "}
                                                          {tab == index ? (
                                                              <i className="fas fas fa-arrow-up fa-2x">
                                                                  {" "}
                                                              </i>
                                                          ) : (
                                                              <i className="fas fa-arrow-down fa-2x">
                                                                  {" "}
                                                              </i>
                                                          )}{" "}
                                                      </div>
                                                  </Col>
                                              </Row>
                                          </CardHeader>
                                      </Col>
                                      <Col md="1" className="mt-4">
                                          {items.status &&
                                          items.status === 1 ? (
                                              <UncontrolledDropdown>
                                                  <DropdownToggle
                                                      className="card-drop"
                                                      tag="i"
                                                  >
                                                      <Button
                                                          type="button"
                                                          className="btn btn-info mt-1"
                                                          id={
                                                              "examsdanger" +
                                                              index
                                                          }
                                                      >
                                                          {" "}
                                                          <i className="bx bx-grid-alt fa-lg text-white" />
                                                      </Button>
                                                      <UncontrolledTooltip
                                                          placement="top"
                                                          target={
                                                              "examsdanger" +
                                                              index
                                                          }
                                                      >
                                                          Ações
                                                      </UncontrolledTooltip>
                                                  </DropdownToggle>
                                                  <DropdownMenu
                                                      right
                                                      className="mt-2"
                                                  >
                                                      <DropdownItem
                                                          id={"bt3" + index}
                                                          onMouseOver={() => {
                                                              handleBG(
                                                                  "bt3" + index
                                                              )
                                                          }}
                                                          onMouseLeave={() => {
                                                              removeBG(
                                                                  "bt3" + index
                                                              )
                                                          }}
                                                          onClick={async () => {
                                                              try {
                                                                  setModalSaveModels(
                                                                      {
                                                                          show: true,
                                                                          data: item,
                                                                      }
                                                                  )
                                                              } catch (e) {
                                                                  console.log(e)
                                                              }
                                                          }}
                                                      >
                                                          <i className="fas fa-plus"></i>{" "}
                                                          Salvar Como Modelo
                                                          {/* <Button
                                                              id="iconedittooltip9349"
                                                              color="primary"
                                                              onClick={async () => {
                                                                  try {
                                                                      setModalSaveModels(
                                                                          {
                                                                              show: true,
                                                                              data: item,
                                                                          }
                                                                      )
                                                                  } catch (e) {
                                                                      console.log(
                                                                          e
                                                                      )
                                                                  }
                                                              }}
                                                          >
                                                              Salvar Como Modelo{" "}
                                                              <i className="fas fa-plus ml-1"></i>
                                                              <UncontrolledTooltip
                                                                  placement="left"
                                                                  target={
                                                                      "iconedittooltip9349"
                                                                  }
                                                              >
                                                                  Clique para
                                                                  adicionar
                                                              </UncontrolledTooltip>
                                                          </Button> */}
                                                      </DropdownItem>
                                                      <DropdownItem
                                                          id={"bt4" + index}
                                                          onMouseOver={() => {
                                                              handleBG(
                                                                  "bt4" + index
                                                              )
                                                          }}
                                                          onMouseLeave={() => {
                                                              removeBG(
                                                                  "bt4" + index
                                                              )
                                                          }}
                                                          onClick={async () => {
                                                              await handleDelete(
                                                                  item
                                                              ),
                                                                  arrayHelpers.remove(
                                                                      index
                                                                  )
                                                          }}
                                                      >
                                                          <i className="fas fa-trash-alt"></i>{" "}
                                                          Excluir
                                                          {/* <Button
                                                              id={
                                                                  "trash5" +
                                                                  index
                                                              }
                                                              color="danger"
                                                              className="inner "
                                                              onClick={async () => {
                                                                  await handleDelete(
                                                                      item
                                                                  ),
                                                                      arrayHelpers.remove(
                                                                          index
                                                                      )
                                                              }}
                                                          >
                                                              Excluir{" "}
                                                              <i className="fas fa-trash-alt"></i>
                                                              <UncontrolledTooltip
                                                                  placement="left"
                                                                  target={
                                                                      "trash5" +
                                                                      index
                                                                  }
                                                              >
                                                                  Clique para
                                                                  excluir
                                                              </UncontrolledTooltip>
                                                          </Button> */}
                                                      </DropdownItem>
                                                      <DropdownItem
                                                          onClick={() => {
                                                              viewForm(item)
                                                          }}
                                                          id={"bt5" + index}
                                                          onMouseOver={() => {
                                                              handleBG(
                                                                  "bt5" + index
                                                              )
                                                          }}
                                                          onMouseLeave={() => {
                                                              removeBG(
                                                                  "bt5" + index
                                                              )
                                                          }}
                                                      >
                                                          <i className="fas fa-binoculars"></i>{" "}
                                                          Pré-visualizar
                                                      </DropdownItem>
                                                  </DropdownMenu>
                                              </UncontrolledDropdown>
                                          ) : (
                                              ""
                                          )}
                                      </Col>
                                      <Col sm="11">
                                          <Collapse isOpen={tab == index}>
                                              <Row>
                                                  <Col sm="12" className="mt-2">
                                                      <FormGroup>
                                                          <Label>
                                                              {" "}
                                                              <strong>
                                                                  Título
                                                              </strong>{" "}
                                                          </Label>
                                                          <FastField
                                                              name={`items[${index}].title`}
                                                              component={
                                                                  InputTextt
                                                              }
                                                              maxlength="100"
                                                              stateSync={
                                                                  stateSync
                                                              }
                                                              datasyncstate={
                                                                  datasyncstate
                                                              }
                                                              defaultValue={
                                                                  item?.title
                                                              }
                                                              lastFormState={
                                                                  lastFormState
                                                              }
                                                              attendanceId={
                                                                  attendance_id
                                                              }
                                                              entityId={
                                                                  item?.id
                                                              }
                                                              itemExam={item}
                                                              disabled={
                                                                  items.status ===
                                                                  0
                                                                      ? true
                                                                      : false
                                                              }
                                                              style={
                                                                  items.status ===
                                                                  0
                                                                      ? {
                                                                            cursor:
                                                                                "not-allowed",
                                                                            background:
                                                                                "#f8f9fa",
                                                                        }
                                                                      : {
                                                                            cursor:
                                                                                "text",
                                                                        }
                                                              }
                                                          />
                                                      </FormGroup>
                                                  </Col>
                                              </Row>
                                              <FieldArray
                                                  name={`items[${index}].itemsExam`}//items[${index}].itemsExam`
                                                  
                                                  render={arrayHelpersExam => (
                                                    <div>
                                                      {items?.items[index]?.itemsExam && 
                                                        items?.items[index].itemsExam.length > 0 ?
                                                        <Row className="mb-3">
                                                            <Col>
                                                                <Table className="table table-bordered table-striped" id="TableItems">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            {/* <th  scope="col">
                                                                            Selecione
                                                                            </th> */}
                                                                            <th  scope="col">Serviços</th>
                                                                            {/* <th  scope="col">Quantidade</th> */}
                                                                            {items.status &&
                                                                                    items.status ===
                                                                                        1 ?
                                                                            <th  scope="col"></th>
                                                                            :""}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>{
                                                                     items.items[
                                                                            index
                                                                        ].itemsExam.map(
                                                                            (
                                                                                itemExam,
                                                                                indexExam
                                                                            ) => (
                                                                             <tr key={indexExam}>
                                                                                {/* <td >
                                                                                <FormGroup>
                                                                                    <div className="custom-control custom-checkbox pt-2 ">                                                      
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            id={`items[${index}].itemsExam[${indexExam}].selected`}
                                                                                            defaultChecked={itemExam?.selected}
                                                                                            //id="customCheck"
                                                                                            //name={`items[${index}].itemsExam[${indexExam}].checked`}
                                                                                            //checked={defaultExamValues.checked}
                                                                                            onChange={async (e) => {
                                                                                                itemExam.selected = e.target.checked
                                                                                                if(!itemExam.selected){
                                                                                                    await handleDeleteExamItem(
                                                                                                        itemExam.id
                                                                                                    )
                                                                                                }
                                                                                                if(itemExam.selected){
                                                                                                    handleItemExam(itemExam)
                                                                                                }
                                                                                                // }else{
                                                                                                //     handleItemExam(itemExam)
                                                                                                // }
                                                                                            }}
                                                                                            disabled={
                                                                                                items.status ===
                                                                                                0
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            />
                                                                                        <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor={`items[${index}].itemsExam[${indexExam}].selected`}
                                                                                        >
                                                                                            {indexExam+1}
                                                                                        </label>
                                                                                    </div>
                                                                                </FormGroup>
                                                                                </td> */}
                                                                                <td style={{width: "100%"}}>
                                                                                <FormGroup style={{marginBottom:"0px"}}>
                                                                                    <FastField
                                                                                        name={`items[${index}].itemsExam[${indexExam}].item_id`}
                                                                                        placeholder="Busque ou Adicione"
                                                                                        cacheOptions
                                                                                        defaultOptions
                                                                                        loadOptions={
                                                                                            debounceOptionsItem
                                                                                        }
                                                                                        defaultValue={
                                                                                            itemExam?.item_id
                                                                                        }
                                                                                        stateSync={
                                                                                            stateSync
                                                                                        }
                                                                                        datasyncstate={
                                                                                            datasyncstate
                                                                                        }
                                                                                        component={
                                                                                            AsyncSelectInputt
                                                                                        }
                                                                                        lastFormState={
                                                                                            lastFormState
                                                                                        }
                                                                                        attendanceId={
                                                                                            attendance_id
                                                                                        }
                                                                                        entityId={
                                                                                            itemExam?.id
                                                                                        }
                                                                                        itemExam={
                                                                                            itemExam
                                                                                        }
                                                                                        isDisabled={
                                                                                            items.status ===
                                                                                            0
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                                
                                                                                </td>
                                                                                {/* <td style={{width: "50%"}}>
                                                                                <FormGroup style={{marginBottom:"0px"}}>
                                                                                    <FastField
                                                                                        name={`items[${index}].itemsExam[${indexExam}].quantity`}
                                                                                        placeholder="Quantidade"
                                                                                        defaultValue={
                                                                                            itemExam.quantity
                                                                                        }
                                                                                        stateSync={
                                                                                            stateSync
                                                                                        }
                                                                                        datasyncstate={
                                                                                            datasyncstate
                                                                                        }
                                                                                        component={
                                                                                            NumberInput
                                                                                        }
                                                                                        lastFormState={
                                                                                            lastFormState
                                                                                        }
                                                                                        attendanceId={
                                                                                            attendance_id
                                                                                        }
                                                                                        entityId={
                                                                                            itemExam.id
                                                                                        }
                                                                                        itemExam={
                                                                                            itemExam
                                                                                        }
                                                                                        disabled={
                                                                                            items.status ===
                                                                                            0
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        style={
                                                                                            items.status ===
                                                                                            0
                                                                                                ? {
                                                                                                        cursor:
                                                                                                            "not-allowed",
                                                                                                        background:
                                                                                                            "#f8f9fa",
                                                                                                    }
                                                                                                : {
                                                                                                        cursor:
                                                                                                            "text",
                                                                                                    }
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                                
                                                                            </td> */}
                                                                            {items.status &&
                                                                            items.status ===
                                                                                1 ? (
                                                                            <td>
                                                                            <FormGroup style={{marginBottom:"0px"}}>
                                                                                <Button
                                                                                    id={
                                                                                        "trash4" +
                                                                                        index
                                                                                    }
                                                                                    color="danger"
                                                                                    className="inner "
                                                                                    onClick={async () => {
                                                                                        await handleDeleteExamItem(
                                                                                            itemExam.id
                                                                                        ),
                                                                                            arrayHelpersExam.remove(
                                                                                                indexExam
                                                                                            )
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-trash-alt"></i>
                                                                                    <UncontrolledTooltip
                                                                                        placement="top"
                                                                                        target={
                                                                                            "trash4" +
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        Clique
                                                                                        para
                                                                                        excluir
                                                                                    </UncontrolledTooltip>
                                                                                </Button>
                                                                            </FormGroup>
                                                                            </td>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                         </tr>
                                                                        ))}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <Col>
                                                                            {items.status &&
                                                                            items.status ===
                                                                                1 ? (
                                                                                <Button 
                                                                                    id="iconedittooltip4"
                                                                                    color="primary"
                                                                                    className="mb-2 mt-2"
                                                                                    onClick={async () => {
                                                                                        let examCreated = await handleCreateExam(
                                                                                            item
                                                                                        )
                                                                                        if (
                                                                                            examCreated
                                                                                        ) {
                                                                                            arrayHelpersExam.push(
                                                                                                examCreated
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-plus"></i>
                                                                                    <UncontrolledTooltip
                                                                                        placement="left"
                                                                                        target={
                                                                                            "iconedittooltip4"
                                                                                        }
                                                                                    >
                                                                                        Clique
                                                                                        para
                                                                                        adicionar
                                                                                    </UncontrolledTooltip>
                                                                                </Button>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </Col>
                                                                    </tfoot>

                                                                </Table>
                                                            </Col>
                                                        
                                                        </Row>
                                                        :
                                                        <Row className="mb-3">
                                                              <Col sm="6">
                                                                {items.status &&
                                                                items.status === 1 ? 
                                                                <Button
                                                                    color="primary"
                                                                    onClick={async () => {
                                                                        let examCreated = await handleCreateExam(
                                                                            item
                                                                        )
                                                                        if (
                                                                            examCreated
                                                                        ) {
                                                                            arrayHelpersExam.push(
                                                                                examCreated
                                                                            )
                                                                        }
                                                                    }}
                                                                    >Adicionar um Serviço</Button>
                                                                :""}
                                                              </Col>
                                                        </Row>
                                                        
                                                        }
                                                        
                                                    </div>
                                                     /* <div>
                                                          {items?.items[index]
                                                              ?.itemsExam &&
                                                          items?.items[index]
                                                              .itemsExam
                                                              .length > 0
                                                              ? items.items[
                                                                    index
                                                                ].itemsExam.map(
                                                                    (
                                                                        itemExam,
                                                                        indexExam
                                                                    ) => (
                                                                        <div>
                                                                            <Row
                                                                                key={
                                                                                    indexExam
                                                                                }
                                                                            >
                                                                                <Col sm="6">
                                                                                    <FormGroup>
                                                                                        <Label>
                                                                                            Item
                                                                                        </Label>
                                                                                        {/* {console.log(
                                                                                            "haha",
                                                                                            itemExam,
                                                                                            indexExam
                                                                                        )} }*/
                                                                                        /*
                                                                                        <FastField
                                                                                            name={`items[${index}].itemsExam[${indexExam}].item_id`}
                                                                                            placeholder="Busque ou Adicione"
                                                                                            cacheOptions
                                                                                            defaultOptions
                                                                                            loadOptions={
                                                                                                debounceOptionsItem
                                                                                            }
                                                                                            defaultValue={
                                                                                                itemExam?.item_id
                                                                                            }
                                                                                            stateSync={
                                                                                                stateSync
                                                                                            }
                                                                                            datasyncstate={
                                                                                                datasyncstate
                                                                                            }
                                                                                            component={
                                                                                                AsyncSelectInputt
                                                                                            }
                                                                                            lastFormState={
                                                                                                lastFormState
                                                                                            }
                                                                                            attendanceId={
                                                                                                attendance_id
                                                                                            }
                                                                                            entityId={
                                                                                                itemExam?.id
                                                                                            }
                                                                                            itemExam={
                                                                                                itemExam
                                                                                            }
                                                                                            isDisabled={
                                                                                                items.status ===
                                                                                                0
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col sm="4">
                                                                                    <FormGroup>
                                                                                        <Label>
                                                                                            Quantidade
                                                                                        </Label>
                                                                                        <FastField
                                                                                            name={`items[${index}].itemsExam[${indexExam}].quantity`}
                                                                                            placeholder="Quantidade"
                                                                                            defaultValue={
                                                                                                itemExam.quantity
                                                                                            }
                                                                                            stateSync={
                                                                                                stateSync
                                                                                            }
                                                                                            datasyncstate={
                                                                                                datasyncstate
                                                                                            }
                                                                                            component={
                                                                                                NumberInput
                                                                                            }
                                                                                            lastFormState={
                                                                                                lastFormState
                                                                                            }
                                                                                            attendanceId={
                                                                                                attendance_id
                                                                                            }
                                                                                            entityId={
                                                                                                itemExam.id
                                                                                            }
                                                                                            itemExam={
                                                                                                itemExam
                                                                                            }
                                                                                            disabled={
                                                                                                items.status ===
                                                                                                0
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            style={
                                                                                                items.status ===
                                                                                                0
                                                                                                    ? {
                                                                                                          cursor:
                                                                                                              "not-allowed",
                                                                                                          background:
                                                                                                              "#f8f9fa",
                                                                                                      }
                                                                                                    : {
                                                                                                          cursor:
                                                                                                              "text",
                                                                                                      }
                                                                                            }
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>

                                                                                <Col
                                                                                    sm="2"
                                                                                    className="mt-1"
                                                                                >
                                                                                    <FormGroup className="mt-4">
                                                                                        {items.status &&
                                                                                        items.status ===
                                                                                            1 ? (
                                                                                            <Button
                                                                                                id={
                                                                                                    "trash4" +
                                                                                                    index
                                                                                                }
                                                                                                color="danger"
                                                                                                className="inner "
                                                                                                onClick={async () => {
                                                                                                    await handleDeleteExamItem(
                                                                                                        itemExam.id
                                                                                                    ),
                                                                                                        arrayHelpersExam.remove(
                                                                                                            indexExam
                                                                                                        )
                                                                                                }}
                                                                                            >
                                                                                                <i className="fas fa-trash-alt"></i>
                                                                                                <UncontrolledTooltip
                                                                                                    placement="top"
                                                                                                    target={
                                                                                                        "trash4" +
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    Clique
                                                                                                    para
                                                                                                    excluir
                                                                                                </UncontrolledTooltip>
                                                                                            </Button>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                )
                                                              : null}
                                                          <Row className="mb-3">
                                                              <Col sm="6">
                                                                  {items.status &&
                                                                  items.status ===
                                                                      1 ? (
                                                                      <Button
                                                                          id="iconedittooltip4"
                                                                          color="primary"
                                                                          onClick={async () => {
                                                                              try {
                                                                                  let examCreated = await handleCreateExam(
                                                                                      item
                                                                                  )
                                                                                  if (
                                                                                      examCreated
                                                                                  ) {
                                                                                      arrayHelpersExam.push(
                                                                                          examCreated
                                                                                      )
                                                                                  }
                                                                              } catch (e) {
                                                                                  console.log(
                                                                                      e
                                                                                  )
                                                                              }
                                                                          }}
                                                                      >
                                                                          <i className="fas fa-plus"></i>
                                                                          <UncontrolledTooltip
                                                                              placement="left"
                                                                              target={
                                                                                  "iconedittooltip4"
                                                                              }
                                                                          >
                                                                              Clique
                                                                              para
                                                                              adicionar
                                                                          </UncontrolledTooltip>
                                                                      </Button>
                                                                  ) : (
                                                                      ""
                                                                  )}
                                                              </Col>
                                                          </Row>
                                                      </div>
                                                      */
                                                  )}
                                              />
                                              <Row>
                                                  <Col sm="12">
                                                      <FormGroup>
                                                          <Label>
                                                              {" "}
                                                              <strong>
                                                                  Texto
                                                              </strong>{" "}
                                                          </Label>
                                                          <FastField
                                                              name={`items[${index}].text_object`}
                                                              editorState={
                                                                  item?.text_object
                                                              }
                                                              component={
                                                                  InputEditor
                                                              }
                                                              stateSync={
                                                                  stateSync
                                                              }
                                                              datasyncstate={
                                                                  datasyncstate
                                                              }
                                                              lastFormState={
                                                                  lastFormState
                                                              }
                                                              attendanceId={
                                                                  attendance_id
                                                              }
                                                              entityId={
                                                                  item?.id
                                                              }
                                                              itemExam={item}
                                                              disabled={
                                                                  items.status ===
                                                                  0
                                                                      ? true
                                                                      : false
                                                              }
                                                          />
                                                      </FormGroup>
                                                  </Col>
                                              </Row>
                                          </Collapse>
                                      </Col>
                                  </Row>
                              ))
                            : null}
                        {items.status && items.status === 1 ? (
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        {/* Visível somente em tamanhos de tela xs, sm */}
                                        <Button
                                            type="button"
                                            className="btn btn-info mt-2 mr-2"
                                            onClick={async () => {
                                                try {
                                                    let aux = {prof: professional, patient: patient, unit: unit}
                                                    setModalPrescriptionData(
                                                        {
                                                            show: true,
                                                            entity_type:
                                                                MEDICAL_FORMS_TYPES.CONSENTIMENTO,
                                                            data: aux
                                                        }
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        >
                                             <i className="fas fa-marker"></i> Modelo
                                        </Button>
                                        <Button
                                            type="button"
                                            className="btn btn-info mt-2"
                                            onClick={async () => {
                                                try {
                                                    let item = await handleCreate()
                                                    if (item) {
                                                        arrayHelpers.push(
                                                            item
                                                        )
                                                    }
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        >
                                           <i className="fas fa-plus"></i> Manual
                                        </Button>
                                        <ModalPrescriptions
                                            show={modalPrescriptionData?.show}
                                            entity_type={
                                                modalPrescriptionData?.entity_type
                                            }
                                            data = {modalPrescriptionData?.data}
                                            onClose={response => {
                                                setModalPrescriptionData({
                                                    show: false,
                                                    data: null,
                                                })
                                            }}
                                            handleModel={async data => {
                                                try {
                                                    let stateEditor = EditorState.createWithContent(
                                                        convertFromRaw(
                                                            JSON.parse(
                                                                data?.text_object
                                                            )
                                                        )
                                                    ) 
                                                    let examItem = []

                                                    data.entity = "MedicalExams"
                                                    data.attendance_id = attendance_id
                                                    data.text_object = convertToRaw(
                                                        stateEditor.getCurrentContent()
                                                    )
                                                    let item = await handleCreate(
                                                        data
                                                    )
                                                    let itemModal = { ...item }
                                                    if (data && itemModal) {
                                                        itemModal.title =
                                                            data?.title
                                                        itemModal.text_object = stateEditor
                                                        for(let i=0; i<data.form.length;i++){
                                                            itemModal.itemsExam = data.form[i]?.item.id
                                                            let aux = await handleCreateExam(itemModal)
                                                            let item ={
                                                                    item_id:{
                                                                        label: data.form[i]?.item?.name,
                                                                        value: data.form[i]?.item?.id,
                                                                    },
                                                                    quantity:1,
                                                                    entity: "itemsExam",
                                                                    MedicalExamId: itemModal.id ,
                                                                    id: aux.id,
                                                                }
                                                            examItem.push(item)
                                                        }
                                                        itemModal.itemsExam = examItem
                                                        arrayHelpers.push(
                                                            itemModal
                                                        )
                                                        setModalPrescriptionData(
                                                            {
                                                                show: false,
                                                                data: null,
                                                            }
                                                        )
                                                    }
                                                    dispatch(
                                                        showSweetAlertDialog({
                                                            title:
                                                                "Modelo selecionado! ",
                                                            type: "success",
                                                            showConfirm: true,
                                                        })
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                    dispatch(
                                                        showSweetAlertDialog({
                                                            title:
                                                                "Ocorreu algum problema ! ",
                                                            text:
                                                                "Houve um erro, tente novamente ou contate o suporte.",
                                                            type: "error",
                                                            showConfirm: true,
                                                        })
                                                    )
                                                }
                                            }}
                                        ></ModalPrescriptions>
                                        <ModalSaveModels
                                            show={modalSaveModels.show}
                                            data={modalSaveModels.data}
                                            onClose={response => {
                                                setModalSaveModels({
                                                    show: false,
                                                    data: null,
                                                })
                                            }}
                                            handleSave={async (title, data) => {
                                                try {
                                                    if (
                                                        title == null ||
                                                        !title ||
                                                        title == ""
                                                    ) {
                                                        dispatch(
                                                            showSweetAlertDialog(
                                                                {
                                                                    title:
                                                                        "Modelo não salvo devido a ausência de título!",

                                                                    type:
                                                                        "error",
                                                                    showConfirm: true,
                                                                }
                                                            )
                                                        )
                                                        setModalSaveModels({
                                                            show: false,
                                                            data: null,
                                                        })

                                                        return
                                                    }    
                                                    if(data.itemsExam && (data.itemsExam.find(e=> !e.item_id) || data.itemsExam.find(e=> !e.item_id.value) )){
                                                        return dispatch(
                                                            showSweetAlertDialog({
                                                                title:
                                                                    'Para criar um modelo, todos os items devem estar preenchidos',
                                                                type: "warning",
                                                                showConfirm: true,
                                                            })
                                                        )
                                                    }
                                                    if((!data.itemsExam || data.itemsExam.every(e => !e?.item_id) 
                                                    && ((data?.text_object == null || data?.text_object == "") || 
                                                    (!data?.text_object == null || !data?.text_object == "" && 
                                                    ((convertToRaw(data.text_object.getCurrentContent()).blocks.length ===1) &&
                                                     (convertToRaw(data.text_object.getCurrentContent()).blocks[0].text ==="")))) 
                                                     )){
                                                        dispatch(
                                                                showSweetAlertDialog({
                                                                    title:
                                                                        'Para criar um modelo, é necessário que o campo "Texto" ou "Adicionar Seviço" sejam preenchidos',
                                                                    type: "warning",
                                                                    showConfirm: true,
                                                                })
                                                            )
                                                            return
                                                    }
                                                   
                                                    //data.text_object = EditorState.createEmpty()
                                                    //     if (
                                                    //         data?.itemsExam?.length==0 &&
                                                    //         (convertToRaw(
                                                    //             data.text_object.getCurrentContent()
                                                    //         ).blocks.length ===
                                                    //             1) &&
                                                    //         (convertToRaw(
                                                    //             data.text_object.getCurrentContent()
                                                    //         ).blocks[0].text ===
                                                    //             "")
                                                    //     ) {
                                                    //         dispatch(
                                                    //             showSweetAlertDialog(
                                                    //                 {
                                                    //                     title:
                                                    //                         'Para criar um modelo, é necessário que o campo "Texto" ou "Adicionar Seviço" sejam preenchidos',
                                                    //                     type:
                                                    //                         "warning",
                                                    //                     showConfirm: true,
                                                    //                 }
                                                    //             )
                                                    //         )
                                                    //         return
                                                    //     }
                                                    //     if (
                                                    //         data?.itemsExam?.length>0 &&
                                                    //         (convertToRaw(
                                                    //             data.text_object.getCurrentContent()
                                                    //         ).blocks.length ===
                                                    //             1) &&
                                                    //         (convertToRaw(
                                                    //             data.text_object.getCurrentContent()
                                                    //         ).blocks[0].text ===
                                                    //             "")
                                                    //     ) data.text_object = EditorState.createEmpty()
                                                    // }
                                                    // } else {
                                                    //     dispatch(
                                                    //         showSweetAlertDialog(
                                                    //             {
                                                    //                 title:
                                                    //                     'Para criar um modelo, é necessário que os campos  "Título" e "Texto" sejam preenchidos!',
                                                    //                 type:
                                                    //                     "warning",
                                                    //                 showConfirm: true,
                                                    //             }
                                                    //         )
                                                    //     )
                                                    //     return
                                                    // }
                                                    if(data?.text_object == null || data?.text_object == "") data.text_object = EditorState.createEmpty()
                                                    const dataSave = {
                                                        title: title,
                                                        form_type:
                                                            MEDICAL_FORMS_TYPES.CONSENTIMENTO,
                                                            text: draftToHtml(
                                                                convertToRaw(
                                                                    data?.text_object?.getCurrentContent()
                                                                )
                                                            ),
                                                            text_object: convertToRaw(
                                                                data?.text_object?.getCurrentContent()
                                                            ),
                                                        exam_items_id: data?.id,
                                                    }
                                                    await createModel(dataSave)

                                                    setModalSaveModels({
                                                        show: false,
                                                        data: null,
                                                    })

                                                    dispatch(
                                                        showSweetAlertDialog({
                                                            title:
                                                                "Modelo adicionado com sucesso! ",
                                                            type: "success",
                                                            showConfirm: true,
                                                        })
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        ></ModalSaveModels>
                                    </FormGroup>
                                </Col>
                            </Row>
                        ) : null}
                    </div>
                )}
            />
        </React.Fragment>
    )
}

export default MedicalExams
