import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
    Badge,
    UncontrolledTooltip,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    ButtonDropdown,
} from "reactstrap"

import SALES_ORDER_STATUS from "../../utils/consts/sales-orders-status"

const REASON_DESCRIPTIONS = {
    sales_orders: {
        discount: "Aplicar desconto",
    },
    sales_order_payments: {
        cancel_payment: "Cancelar pagamento",
    },
}

const RequestColumns = (setModalApprovalData) => [
    {
        dataField: "id",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row.id}
            </Link>
        ),
    },
    {
        dataField: "requested_user.name",
        text: "Solicitante",
    },
    {
        dataField: "reason",
        text: "Motivo",
        formatter: (cellContent, row) => {           
            return <span>{ REASON_DESCRIPTIONS[row.entity_type]?.[cellContent] ||
                "Motivo desconhecido"}</span>;
        },
    },
    {
        dataField: "created_at",
        text: "Criado em",
        formatter: (cellContent, row) =>
            new Date(row.created_at).toLocaleDateString('pt-BR', {hour:"2-digit", hour12: false, minute:"2-digit", second:"2-digit"})
    },
    {
        dataField: "entity_type",
        text: "Tipo",
        formatter: (cellContent, row) => {
            let text = ""
            let badgeClass = "badge-soft-primary"
            switch (cellContent) {
                case "sales_orders":
                    text = "Pedido de Venda"
                    badgeClass = "badge-soft-primary"
                    break
                case "sales_order_payments":
                    text = "Pagamento de Pedido de Venda"
                    badgeClass = "badge-soft-primary"
                    break
            }
            return (
                <Badge className={"font-size-12 " + badgeClass} pill>
                    {text}
                </Badge>
            )
        },
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <Link
                to="#"
                className="mr-3 text-primary "
                onClick={() => {
                    setModalApprovalData({ show: true, data: row })
                }}
            >
            <i className="far fa-eye font-size-24" id={"eye"+row.id}></i>
                <UncontrolledTooltip
                    placement="top"
                    target={"eye"+row.id}
                >
                    Abrir
                </UncontrolledTooltip>
            </Link>
        ),
    },
]

export default RequestColumns
