import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import { showSweetAlertDialog, showToast } from "store/layout/actions";
import useCashControl from "../../../services/vitta-core/cash-control";
import CashMovesDataTable from "./components/CashMovesDataTable";
import CashControlDashboard from "./components/CashControlDashboard.jsx";
import NumberFormat from "react-number-format";

/**
 * Componente principal de listagem de controle de caixa.
 * Gerencia a abertura, fechamento e transferência entre caixas.
 */
const ListCashControl = () => {
    const {
        findLastCashControlByUserId,
        openCashControlService,
        closeCashControlService,
        findOpenCashControlsToday,
        transferCashControl,
        checkBankTransferPermission,
    } = useCashControl();

    const dispatch = useDispatch();
    const Scope = useSelector((state) => state.scope);
    const [cashControl, setCashControl] = useState(null);
    const [isCashOpen, setIsCashOpen] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canTransfer, setCanTransfer] = useState(false);
    const [openModalOpen, setOpenModalOpen] = useState(false);
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [transferAmount, setTransferAmount] = useState(0);
    const [openCashControls, setOpenCashControls] = useState([]);
    const [selectedCashControl, setSelectedCashControl] = useState(null);
    const [initialValue, setInitialValue] = useState(0);
    const [loadingOpenCashControl, setLoadingOpenCashControl] = useState(false);
    const [loadingTransfer, setLoadingTransfer] = useState(false);
    const cashMovesTableRef = useRef(null);
    const unitId = Scope?.unit?.id;

    const checkCashControlStatus = async () => {
        try {
            const result = await findLastCashControlByUserId(unitId);
            setCashControl(result);

            const isClosed =
                (result.data.close_value !== null && result.data.close_at !== null) || !result?.data;
            setIsCashOpen(!isClosed);
            setCanAdd(!isClosed);
            cashMovesTableRef.current.refreshTable();
        } catch (error) {
            console.error("Erro ao verificar status do caixa", error);
        }
    };

    const fetchOpenCashControls = async () => {
        try {
            const result = await findOpenCashControlsToday(unitId);
            setOpenCashControls(result);
        } catch (error) {
            console.error("Erro ao buscar cash controls abertos", error);
        }
    };

    const handleOpenTransferModal = async () => {
        setOpenTransferModal(true);
        await fetchOpenCashControls();
    };

    const fetchBankTransferPermission = async () => {
        try {
            const permission = await checkBankTransferPermission();
            setCanTransfer(permission);
        } catch (error) {
            setCanTransfer(false);
        }
    };

    useEffect(() => {
        checkCashControlStatus();
        fetchBankTransferPermission();
    }, []);

    const openCashControl = async (value) => {
        setLoadingOpenCashControl(true);
        try {
            const unitId = Scope?.unit?.id;
            if (!unitId) {
                throw new Error("Unidade não identificada. Verifique o Scope.");
            }

            const response = await openCashControlService(value, unitId);

            if (response) {
                dispatch(
                    showToast({
                        title: "Sucesso",
                        text: "Caixa aberto com sucesso!",
                        type: "success",
                    })
                );
                checkCashControlStatus();
            }
        } catch (error) {
            const errorMessage =
                error.response?.data?.error_message ||
                "Não foi possível abrir o caixa. Tente novamente mais tarde.";
            dispatch(
                showToast({
                    title: "Erro",
                    text: errorMessage,
                    type: "error",
                })
            );
        } finally {
            setOpenModalOpen(false);
            setLoadingOpenCashControl(false);
        }
    };

    const closeCashControl = async () => {
        if (!cashControl) {
            dispatch(
                showToast({
                    title: "Erro",
                    text: "Nenhum caixa encontrado.",
                    type: "error",
                })
            );
            return;
        }

        try {
            const response = await closeCashControlService(cashControl?.data.id);
            if (response) {
                dispatch(
                    showToast({
                        title: "Sucesso",
                        text: "Caixa fechado com sucesso!",
                        type: "success",
                    })
                );
                setIsCashOpen(false);
                setCanAdd(false);
                cashMovesTableRef.current.refreshTable();
            }
        } catch (error) {
            console.error("Erro ao fechar o caixa", error);
            dispatch(
                showToast({
                    title: "Erro",
                    text: "Não foi possível fechar o caixa. Tente novamente mais tarde.",
                    type: "error",
                })
            );
        }
    };

    const handleCloseCashControl = () => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção",
                text: `Tem certeza que deseja fechar o caixa?`,
                type: "warning",
                onConfirmAction: async () => {
                    await closeCashControl();
                    setIsCashOpen(false);
                    setCanAdd(false);
                    cashMovesTableRef.current.refreshTable();
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        );
    };

    /**
     * Função que lida com a transferência de valor entre caixas.
     */

    const handleTransferCashControl = async () => {
        setLoadingTransfer(true);
        if (!selectedCashControl || transferAmount <= 0) {
            setLoadingTransfer(false);
            return dispatch(
                showToast({
                    title: "Atenção",
                    text: "Selecione um caixa de destino e defina um valor válido.",
                    type: "warning",
                })
            );
        }

        try {
            await transferCashControl(
                cashControl.data.id,
                selectedCashControl,
                transferAmount
            );
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Transferência realizada com sucesso!",
                    type: "success",
                })
            );

            setOpenTransferModal(false);
            checkCashControlStatus();
        } catch (error) {
            const errorMessage =
                error?.response?.data?.error_message ||
                "Erro ao realizar a transferência. Por favor, tente novamente.";
            console.error("Erro ao realizar a transferência:", error);
            dispatch(
                showToast({
                    title: "Erro",
                    text: errorMessage,
                    type: "error",
                })
            );
        } finally {
            setLoadingTransfer(false);
        }
    };

    const renderCashControlButton = () => {
        if (isCashOpen) {
            return (
                <Button type="submit" color="danger" onClick={handleCloseCashControl}>
                    Fechar Caixa
                </Button>
            );
        } else {
            return (
                <Button type="submit" color="success" onClick={() => setOpenModalOpen(true)}>
                    Abrir Caixa
                </Button>
            );
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Usuário" breadcrumbItem="Controle de Caixa" />

                    <Row className="mb-4">
                        <Col xs="12">
                            <CardBody>
                                <CashControlDashboard isCashOpen={isCashOpen} summary={cashControl?.summary} hasBankTransferPermission={canTransfer} />
                            </CardBody>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-2 justify-content-end">
                                        {isCashOpen ? (<>
                                             <Col sm="auto">
                                             <Link
                                                 to={`/controle-caixa/${cashControl?.data?.id}/adicionar`}
                                             >
                                                 <Button type="submit" color="primary">
                                                     Adicionar
                                                 </Button>
                                             </Link>
                                         </Col>
                                         <Col sm="auto">
                                             <Button
                                                 color="info"
                                                 disabled={!isCashOpen}
                                                 onClick={handleOpenTransferModal}
                                             >
                                                 Transferir
                                             </Button>
                                         </Col>
                                         </>) : <>
                                         <Col sm="auto">
                                             <Link
                                                 to={`/controle-caixa/deposito/adicionar`}
                                             >
                                                 <Button type="submit" color="primary">
                                                     Depósito Bancário
                                                 </Button>
                                             </Link>
                                         </Col>
                                         </>}
                                       
                                        <Col sm="auto">{renderCashControlButton()}</Col>
                                    </Row>

                                    <CashMovesDataTable ref={cashMovesTableRef} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={openModalOpen} toggle={() => setOpenModalOpen(!openModalOpen)}>
                <ModalHeader toggle={() => setOpenModalOpen(!openModalOpen)}>
                    Definir valor de abertura
                </ModalHeader>
                <ModalBody>
                    <Label for="initialValue">Valor Inicial</Label>
                    <NumberFormat
                        value={initialValue ? initialValue / 100 : ""}
                        onValueChange={(values) => {
                            const { floatValue } = values;
                            setInitialValue(Math.round(floatValue * 100));
                        }}
                        displayType={"input"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        prefix={"R$ "}
                        placeholder="Digite o valor inicial do caixa"
                        className="form-control"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => openCashControl(initialValue)}
                        disabled={loadingOpenCashControl}
                    >
                        {loadingOpenCashControl ? "Abrindo..." : "Confirmar"}
                    </Button>
                    <Button color="secondary" onClick={() => setOpenModalOpen(false)}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={openTransferModal}
                toggle={() => setOpenTransferModal(!openTransferModal)}
            >
                <ModalHeader toggle={() => setOpenTransferModal(!openTransferModal)}>
                    Transferir valor para outro caixa
                </ModalHeader>
                <ModalBody>
                    <Label for="cashControlDestination">Caixa de Destino</Label>
                    <Input
                        type="select"
                        id="cashControlDestination"
                        value={selectedCashControl}
                        onChange={(e) => setSelectedCashControl(e.target.value)}
                    >
                        <option value="">Selecione o caixa de destino</option>
                        {openCashControls.map((control) => (
                            <option key={control.id} value={control.id}>
                                Caixa de {control.user.name} - Unidade: {control.unit_id}
                            </option>
                        ))}
                    </Input>

                    <Label for="transferAmount" className="mt-3">
                        Valor de Transferência
                    </Label>
                    <NumberFormat
                        value={transferAmount ? transferAmount / 100 : ""}
                        onValueChange={(values) => {
                            const { floatValue } = values;
                            setTransferAmount(Math.round(floatValue * 100));
                        }}
                        displayType={"input"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        prefix={"R$ "}
                        placeholder="Digite o valor a ser transferido"
                        className="form-control"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={handleTransferCashControl}
                        disabled={loadingTransfer}
                    >
                        {loadingTransfer ? "Transferindo..." : "Confirmar Transferência"}
                    </Button>
                    <Button color="secondary" onClick={() => setOpenTransferModal(false)}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default ListCashControl;
