import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { Card, CardBody, Col, Container, Row, Label } from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DocsListColumns from "./ListColumns"
import {
  search as ApprovalRequestSearch,
} from "services/vitta-core/approval-request"
import ModalApprovalAnalyze  from "pages/ApprovalMonitor/ApprovalAnalyze"
import APPROVAL_REQUESTS_STATUS from "utils/consts/approval-requests-status"

const ApprovalMonitor = () => {

  const [ApprovalRequestsList, setApprovalRequestsList] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 10,
    custom: true,
    data: [],
  })

  const [ modalApprovalData, setModalApprovalData] = useState({
    show: false,
    data: null
  })

  const inputEntityType = useRef();

  const search = async (filter, page) => {
    let sizePerPage = 10
    try {
        let data = await ApprovalRequestSearch(
            filter,
            page,
            sizePerPage
        )

        console.log("data",data)

        setApprovalRequestsList({
            page: page,
            sizePerPage: sizePerPage,
            totalSize: data.total_pages*sizePerPage,
            custom: true,
            data: data.data,
        })
    } catch (e) {
        console.log("error", e)
    }
}

useEffect(() => {
  let searchObj = { };

  searchObj.expand_requested_user = true;
  searchObj.status = APPROVAL_REQUESTS_STATUS.OPEN;

  search(searchObj, 1)
}, []);

const searchRequests = (page = 1) => {

  let searchObj = { };
  let entity_type = inputEntityType.current?.state?.value?.value;


  searchObj.expand_requested_user = true;
  searchObj.status = APPROVAL_REQUESTS_STATUS.OPEN;
  
  if(entity_type) searchObj.entity_type = entity_type;

  search(searchObj, page)
};

const onTableChange = (a,b,c) => {
  if(a == "pagination")
  {
    searchRequests(b.page)
  }
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Monitor" breadcrumbItem="Monitor de Aprovação" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(ApprovalRequestsList)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={ApprovalRequestsList.data || []}
                        columns={DocsListColumns(setModalApprovalData)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-4">
                              <Col sm="3">
                                <Label>Filtrar Tipo</Label>
                                <Select 
                                  ref={inputEntityType} 
                                  defaultValue={{
                                    label: "Pedido de Venda", value: "sales_orders"
                                  }}
                                  options={ [
                                    {
                                      label: "Pedido de Venda", value: "sales_orders"
                                    },
                                    {
                                      label: "Pagamento de Pedido de Venda", value: "sales_order_payments"
                                    }
                                  ] }
                                />
                              </Col>
                              
                              <Col sm="1">
                                <div className="">
                                  <Link className="btn btn-primary mt-4" onClick={ () => searchRequests() }>
                                      Buscar
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={
                                      onTableChange
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ModalApprovalAnalyze 
          show={modalApprovalData.show} 
          data={modalApprovalData.data} 
          onClose={(operations) => {
            setModalApprovalData({show: false, data: null})
            if(operations && operations.analyzed) searchRequests();
          }}
        >
        </ModalApprovalAnalyze>
      </div>
    </React.Fragment>
  )
}

export default ApprovalMonitor
