import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
//units
import CreateUnit from "../pages/Units/CreateUnit"
import Units from "../pages/Units/ListUnit"
//professionals
import Professionals from "../pages/Professionals/Index"
import RegistrationProfessionals from "pages/Professionals/RegistrationProfessionals/Index"

//users
import Users from "../pages/Users/Index"
import RegistrationUsers from "../pages/Users/RegistrationUsers/Index"
import handleUsers from "pages/Users/HandleUsers/handleUsers"
//ACCESSGROUPS
import Accessgroups from "pages/AccessGroups/index"

//PATIENTS
import RegistrationPatients from "pages/Patients/Detail/pages/PersonalData"
import Patients from "pages/Patients/ListPatients/index"
import Detail from "pages/Patients/Detail/Index"

//sales orders
import SalesOrders from "pages/SalesDocs/ListOrders"
import SalesQuotes from "pages/SalesDocs/ListQuotes/index"
import RegistrationSalesDocs from "pages/SalesDocs/Registration/index"

//relatórios
import PaymentReceived from "pages/Reports/PaymentReceived/index"
import ReportPaymentReceived from "pages/Reports/PaymentReceived/report"
import MedicalAssistence from "pages/Reports/MedicalAssistence"
import MedicalAssistenceReport from "pages/Reports/MedicalAssistence/report"
import ExecComission from "pages/Reports/ExecComissions"
import OrderService from "pages/Reports/OrderService/index"
//parcerios
import RegistrationPartners from "pages/Partners/RegistrationPartners/index"
import ListPartners from "pages/Partners/ListPartners/index"
//permissao
import ListPermissions from "pages/Permissions/ListPermissions"
import RegistrationPermissions from "pages/Permissions/RegistrationPermissions"
//perfil de acesso
import ListAccessRoles from "pages/Access Roles/ListAccessRoles"
import RegistrationAccessRoles from "pages/Access Roles/RegistrationAccessRoles"
//preço dos itens
import ListPriceItems from "pages/PriceItems/ListPriceItems"
import RegistrationPriceItems from "pages/PriceItems/RegistrationPriceItems"

//agenda
import ScheduleSettingList from "pages/Schedule/ScheduleSetting/List/Index"
import ScheduleSettingRegistration from "pages/Schedule/ScheduleSetting/Registration/Index"
import ScheduleLockList from "pages/Schedule/ScheduleLock/List/index"
import ScheduleLockRegistration from "pages/Schedule/ScheduleLock/Registration/index"
import Appointments from "pages/Appointments/List"
//approvalMonitor
import ApprovalMonitor from "pages/ApprovalMonitor"
//listOrderService
import listOrderService from "pages/OrderService/listOrderService/index"
import detailOrderService from "pages/OrderService/DetailOrderService/DetailOrderService"

import SalesInvoiceEmission from "pages/SalesDocs/SalesInvoiceEmission"
import Invoice from "pages/SalesDocs/ListInvoices/index"

import ExecutionComissions from "pages/ExecutionComissions/index"
import AllProfessional from "pages/Reports/ReportAllProfessional"
import GenTransferInvoices from "pages/ProfessionalPaymentTransfer/GenTransferInvoices"
import ListTransferInvoices from "pages/ProfessionalPaymentTransfer/ListTransferInvoices"
import ProfessionalPaymentTransferReport from "pages/Reports/ProfessionalPaymentTransfer"
import VittaCardContract from "pages/Reports/VittaCardContract/index"
import ProfessionalPaymentTransferHistoric from "pages/Reports/ProfessionalPaymentTransfer/historicTransfer"
import ProfessionalPaymentTransferDemonstrative from "pages/Reports/ProfessionalPaymentTransfer/demonstrative"
import ProfessionalPaymentTransferTransferNote from "pages/Reports/ProfessionalPaymentTransfer/transferNotes"
import printTransferNotes from "pages/ProfessionalPaymentTransfer/ComponentsInvoices/printTransferNotes"
import printExamRequests from "pages/SalesDocs/ModalExamRequests/Components/printExamRequests"
import ProfessionalPaymentTransferReceivable from "pages/Reports/ProfessionalPaymentTransfer/receivable"
import Integrations from "pages/Integrations/index"

import ExecutionOS from "pages/Reports/ExecutionOrderService/index"
import Help from "pages/Help"

import SalesAnalysis from "pages/Reports/SalesAnalysis"
import SalesOrderAnalytical from "pages/Reports/SalesOrderAnalytical"
import SalesQuoteAnalytical from "pages/Reports/SalesQuoteAnalytical/index"
import Appointment from "pages/Reports/Appointment/index"
import ChartExport from "pages/Patients/Detail/pages/ExportAttendancePatient/index"
import PceReport from "pages/Reports/PceReport/index"
import DentalEvaluationList from "pages/SalesDocs/DentalEvaluations/Index"
import DentalEvaluationDetails from "pages/SalesDocs/DentalEvaluations/Form/Index"
import PrintOdontologicQuote from "pages/Reports/Odontologic/printOdontologicData"
import OdontoExec from "pages/Reports/OdontoExc"
import ExecutionControl from "pages/ExecutionControl/Index"
import ReceiptReport from "pages/Reports/ReceiptReport"

//controle de caixa
import CashControlList from "pages/CashControl/List/index"
import CashControlRegistration from "pages/CashControl/Registration/index"
import CashControlDetails from "pages/CashControl/Details/index"
import FinancialControlReport from "pages/Reports/FinancialControlReport"


import AccountDigital from "../pages/AccountDigital/index"
const userRoutes = [
    { path: "/dashboard", component: Dashboard },

    {
        path: "/ajuda",
        component: Help,
        menuIcon: "fas fa-sharp fa-solid fa-question",
        menuName: "Ajuda",
        menuParent: "help",
    },
    //UNITS
    {
        path: "/unidades",
        component: Units,
        permissions: ["view_unit"],
        menuName: "Unidades",
        menuParent: "gestao",
    },
    {
        path: "/unidades/adicionar",
        component: CreateUnit,
        permissions: ["add_unit"],
    },
    {
        path: "/unidades/editar/:id",
        component: CreateUnit,
        permissions: ["view_unit"],
    },

    //USERS
    {
        path: "/usuarios",
        component: Users,
        permissions: ["view_user"],
        menuName: "Usuários",
        menuParent: "gestao",
    },
    {
        path: "/usuarios/adicionar",
        component: RegistrationUsers,
        permissions: ["view_user"],
    },
    {
        path: "/usuarios/editar/:id",
        component: RegistrationUsers,
        permissions: ["edit_user"],
    },
    { path: "/conta", component: handleUsers },

    //PROFESSIONALS
    {
        path: "/profissionais",
        component: Professionals,
        permissions: ["view_professional"],
        menuName: "Profissionais",
        menuParent: "gestao",
    },
    {
        path: "/profissionais/adicionar",
        component: RegistrationProfessionals,
        permissions: ["view_professional"],
    },
    {
        path: "/profissionais/editar/:id",
        component: RegistrationProfessionals,
        permissions: ["edit_professional"],
    },

    //comissions
    {
        path: "/comissoes-execucao",
        component: ExecutionComissions,
        permissions: ["view_exec_comissions"],
        menuName: "Comissões de Execução",
        menuParent: "gestao",
    },

    //ACCESSGROUPS
    //{ path: "/gruposdeacesso", component: Accessgroups },
    //PATIENTS
    {
        path: "/pacientes",
        component: Patients,
        permissions: ["view_patient"],
        menuIcon: "fas fa-hospital-user",
        menuName: "Pacientes",
        menuParent: "patient",
    },
    {
        path: "/pacientes/adicionar",
        component: RegistrationPatients,
        permissions: ["add_patient"],
    },
    {
        path: "/pacientes/:id/exportacao-prontuario",
        component: ChartExport,
        permissions: ["view_patient"],
    },
    {
        path: "/pacientes/:id/*",
        component: Detail,
        permissions: ["view_patient"],
    },

    //sales docs
    {
        path: "/avaliacoes",
        component: DentalEvaluationList,
        permissions:["manage_assessments"],
        menuName: "Avaliações",
        menuParent: "vendas",
    },
    {
        path: "/avaliacoes/:id",
        component: DentalEvaluationDetails,
        permissions:["manage_assessments"]
    },
    {
        path: "/orcamentos",
        component: SalesQuotes,
        permissions: ["view_sales_quote"],
        menuName: "Orçamentos",
        menuParent: "vendas",
    },
    {
        path: "/orcamentos/adicionar",
        component: RegistrationSalesDocs,
        permissions: ["add_sales_quote"],
    },
    {
        path: "/orcamentos/:id",
        component: RegistrationSalesDocs,
        permissions: ["view_sales_quote"],
    },

    {
        path: "/pedidos",
        component: SalesOrders,
        permissions: ["view_sales_order"],
        menuName: "Pedidos",
        menuParent: "vendas",
    },
    {
        path: "/pedidos/adicionar",
        component: RegistrationSalesDocs,
        permissions: ["add_sales_order"],
    },
    {
        path: "/pedidos/:id",
        component: RegistrationSalesDocs,
        permissions: ["view_sales_order"],
    },

    {
        path: "/notas-fiscais",
        component: Invoice,
        permissions: ["view_sales_order"],
        menuName: "Notas Fiscais",
        menuParent: "vendas",
    },
    {
        path: "/emissao-nfe",
        component: SalesInvoiceEmission,
        permissions: ["emit_sales_invoice"],
        menuName: "Emissão NFe",
        menuParent: "vendas",
    },
    //parceiros
    {
        path: "/parceiros",
        component: ListPartners,
        permissions: ["view_partner"],
        menuName: "Parceiros",
        menuParent: "gestao",
    },
    {
        path: "/parceiros/adicionar",
        component: RegistrationPartners,
        permissions: ["add_partner"],
    },
    {
        path: "/parceiros/editar/:id",
        component: RegistrationPartners,
        permissions: ["view_partner"],
    },

    //permisões
    {
        path: "/permissoes",
        component: ListPermissions,
        permissions: ["manage_permissions"],
        menuName: "Permissões",
        menuParent: "gestao",
    },
    {
        path: "/permissoes/adicionar",
        component: RegistrationPermissions,
        permissions: ["manage_permissions"],
    },
    {
        path: "/permissoes/editar/:id",
        component: RegistrationPermissions,
        permissions: ["manage_permissions"],
    },
    //perfil de acesso
    {
        path: "/perfil-de-acesso",
        component: ListAccessRoles,
        permissions: ["manage_access_roles"],
        menuName: "Perfis de Acesso",
        menuParent: "gestao",
    },
    {
        path: "/perfil-de-acesso/adicionar",
        component: RegistrationAccessRoles,
        permissions: ["manage_access_roles"],
    },
    {
        path: "/perfil-de-acesso/editar/:id",
        component: RegistrationAccessRoles,
        permissions: ["manage_access_roles"],
    },
    //preço dos itens
    {
        path: "/servicos",
        component: ListPriceItems,
        permissions: ["manage_items"],
        menuName: "Serviços",
        menuParent: "gestao",
    },
    {
        path: "/servicos/adicionar",
        component: RegistrationPriceItems,
        permissions: ["manage_items"],
    },
    {
        path: "/servicos/editar/:id",
        component: RegistrationPriceItems,
        permissions: ["manage_items"],
    },

    //agenda
    {
        path: "/agendas",
        component: ScheduleSettingList,
        permissions: ["view_appointment_book"],
        menuName: "Agendas",
        menuParent: "agenda",
    },
    {
        path: "/agendas/adicionar",
        component: ScheduleSettingRegistration,
        permissions: ["add_appointment_book"],
    },
    {
        path: "/agendas/editar/:id",
        component: ScheduleSettingRegistration,
        permissions: ["view_appointment_book"],
    },
    {
        path: "/agendas/copy-from/:copy",
        component: ScheduleSettingRegistration,
        permissions: ["view_appointment_book"],
    },
    {
        path: "/agendamentos",
        component: Appointments,
        permissions: ["view_appointment"],
        menuName: "Agendamentos",
        menuParent: "agenda",
    },

    {
        path: "/bloqueio-agendas",
        component: ScheduleLockList,
        permissions: ["view_appointment_book_lock"],
        menuName: "Bloqueio de Agendas",
        menuParent: "agenda",
    },
    {
        path: "/bloqueio-agendas/adicionar",
        component: ScheduleLockRegistration,
        permissions: ["add_appointment_book_lock"],
    },
    {
        path: "/bloqueio-agendas/editar/:id",
        component: ScheduleLockRegistration,
        permissions: ["view_appointment_book_lock"],
    },
    {
        path: "/imprimir/pedidos-de-exame/:id",
        component: printExamRequests,
    },
    {
        path: "/controle-execucao",
        component: ExecutionControl,
        menuName: "Controle de Execuções",
        menuIcon: "fas fa-vote-yea",
        menuParent: "integrations",
        // permissions: ["view_execution_controls"],
    },
    {
        path: "/conta-digital",
        component: AccountDigital,
        menuIcon: "fas fa-sharp fa-solid fa-wallet",
        permissions: ["view_account_digital"],
        menuName: "Conta Digital",
        menuParent: "account_digital",
    },
    {
        path: "/monitor-aprovacao",
        component: ApprovalMonitor,
        permissions: ["sales_order_approval"],
        menuIcon: "fas fa-chalkboard-teacher",
        menuName: "Monitor de Aprovação",
        menuParent: "approval_monitor",
    },

    //ordens de serviço
    // {path:"/ordens-servico",component:listOrderService, permissions: ["view_os"], menuIcon:"fas fa-suitcase",  menuName: "Ordens de Serviço", menuParent: "os"},
    // {path:"/ordens-servico/:id",component:detailOrderService, permissions: ["view_os"]},

    {
        path: "/notas-repasse",
        component: ListTransferInvoices,
        permissions: ["view_prof_transfer_pay"],
        menuName: "Notas de Repasse",
        menuParent: "prof_transfer_pay",
    },
    {
        path: "/repasse-medico",
        component: GenTransferInvoices,
        permissions: ["view_prof_transfer_pay"],
        menuName: "Gerar Notas",
        menuParent: "prof_transfer_pay",
    },
    {
        path: "/imprimir/nota-repasse-medico/:id",
        component: printTransferNotes,
    },

    //integrações
    {
        path: "/integracoes",
        component: Integrations,
        menuName: "Integrações",
        menuIcon: "fas fa-tools",
        menuParent: "integrations",
        permissions: ["view_integration"],
    },

    //Relatórios
    {
        path: "/relatorios/pagamentos-recebidos",
        component: PaymentReceived,
        permissions: ["view_sales_order_payment"],
        menuName: "Pag. Recebidos",
        menuParent: "reports",
    },
    {
        path: "/relatorios/recebimentos",
        component: ReceiptReport,
        permissions: ["view_sales_order_payment"],
        menuName: "Recebimentos",
        menuParent: "reports",
    },
    {
        path: "/relatorios/atend-medicos",
        component: MedicalAssistence,
        permissions: ["list_medical_attendance"],
        menuName: "Atendimentos Médicos",
        menuParent: "reports",
    },
    {
        path: "/relatorios/atend-medicos/view",
        component: MedicalAssistenceReport,
        permissions: ["list_medical_attendance"],
    },

    {
        path: "/relatorios/comissoes-execucao",
        component: ExecComission,
        permissions: ["view_exec_comissions"],
        menuName: "Config. Comissões",
        menuParent: "reports",
    },

    {
        path: "/relatorios/todos-profissionais",
        component: AllProfessional,
        permissions: ["view_professional"],
        menuName: "Profissionais",
        menuParent: "reports",
    },

    {
        path: "/relatorios/repasses-medicos",
        component: ProfessionalPaymentTransferReport,
        permissions: ["view_prof_transfer_pay"],
        menuName: "Repasses Médicos",
        menuParent: "reports",
    },

    {
        path: "/relatorios/repasses-medicos/historico",
        component: ProfessionalPaymentTransferHistoric,
        permissions: ["view_prof_transfer_pay"],
    },
    {
        path: "/relatorios/repasses-medicos/demonstrativo",
        component: ProfessionalPaymentTransferDemonstrative,
        permissions: ["view_prof_transfer_pay"],
    },
    {
        path: "/relatorios/repasses-medicos/recebiveis",
        component: ProfessionalPaymentTransferReceivable,
        permissions: ["view_prof_transfer_pay"],
    },
    {
        path: "/relatorios/repasses-medicos/notas-fiscais",
        component: ProfessionalPaymentTransferTransferNote,
        permissions: ["view_prof_transfer_pay"],
    },
    {
        path: "/relatorios/analise-vendas",
        component: SalesAnalysis,
        permissions: ["view_sales_order"],
        menuName: "Análise de Vendas",
        menuParent: "reports",
    },
    {
        path: "/relatorios/orcamentos-analitico",
        component: SalesQuoteAnalytical,
        permissions: ["view_sales_quote"],
        menuName: "Orçamentos - Analítico",
        menuParent: "reports",
    },
    {
        path: "/relatorios/pedidos-de-venda-analitico",
        component: SalesOrderAnalytical,
        permissions: ["view_sales_order"],
        menuName: "Pedidos de Venda - Analítico",
        menuParent: "reports",
    },
    {
        path: "/relatorios/agendamentos-analitico",
        component: Appointment,
        menuName: "Agendamentos - Analítico",
        menuParent: "reports",
    },
    {
        path: "/relatorios/relatorio-pce",
        component: PceReport,
        menuName: "Relatório PCE",
        permissions: ["view_professional"],
        menuParent: "reports",
    },
    // {
    //     path: "/relatorios/contratos-vitta-card",
    //     component: VittaCardContract,
    //     permissions: ["vitta_card_manage"],
    //     menuName: "Contratos Cartão Vittá +",
    //     menuParent: "reports",
    // },
    {
        path: "/relatorios/dados-odontologicos",
        component: PrintOdontologicQuote,
        permissions:["manage_assessments"]
    },
    {
        path: "/relatorios/execucoes-servicos",
        component: OdontoExec,
       // permissions:["manage_assessments"],
        menuName: "Execução de Serviços",
        menuParent: "reports",
    },
    
//controle de caixa
    {
        path: "/controle-caixa",
        component: CashControlList,
        menuName: "Controle de Caixa",
        menuParent: "caixa",
        menuIcon: "fas fa-cash-register",
        permissions: ["cash_control"],
    },
    {
        path: "/controle-caixa/deposito/adicionar",
        component: CashControlRegistration,
        permissions: ["cash_control"],
    },
    {
        path: "/controle-caixa/:id/adicionar",
        component: CashControlRegistration,
        permissions: ["cash_control"],
    },
    {
        path: "/controle-caixa/:id/editar",
        component: CashControlDetails,
        permissions: ["cash_control"],
    },

 {
        path: "/relatorios/controle-de-caixa",
        component: FinancialControlReport,
        permissions: ["view_sales_order_payment"],
        menuName: "Controle de Caixa",
        menuParent: "reports",
    },

    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
]

export { userRoutes, authRoutes }
