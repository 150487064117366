import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

/**
 * Componente para exibir balões de BI com informações de resumo financeiro.
 * @param {Object} summary - Objeto contendo os dados de resumo financeiro.
 * @param {boolean} hasBankTransferPermission - Indica se o usuário tem permissão de depósito bancário.
 */
const CashControlDashboard = ({ isCashOpen = false, summary = {}, hasBankTransferPermission = false }) => {
    // Valores padrão para o resumo financeiro
    const defaultSummary = {
        PIX: { entrada: 0, saida: 0, emCaixa: 0 },
        Dinheiro: { entrada: 0, saida: 0, emCaixa: 0 },
        Cartao: { entrada: 0, saida: 0, emCaixa: 0 },
        Total: { entrada: 0, saida: 0, emCaixa: 0 },
        Geral: { total: 0 },
    };

    const currentSummary = { ...defaultSummary, ...summary };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
        }).format(value / 100); // valores em centavos
    };

    const getIconClass = (title) => {
        const iconClasses = {
            "PIX": "fas fa-qrcode",
            "Dinheiro": "fas fa-money-bill-wave",
            "Cartão de Crédito": "fas fa-credit-card",
            "Total": "fas fa-coins",
            "Caixa Geral": "fas fa-landmark",
        };
        return iconClasses[title] || "fas fa-wallet";
    };

    const renderBICard = (title, data) => {
        const isCaixaGeral = title === "Caixa Geral";
        const totalValue = isCaixaGeral ? data.total : data.emCaixa;

        return (
            <Col lg="3" md="6" sm="6" className="mb-3 d-flex justify-content-center">
                <Card className="mini-stats-wid" style={{ height: "150px", width: "100%" }}>
                    <CardBody className="shadow-sm bg-white rounded wrap" style={{
                        minHeight: "fit-content"
                    }}>
                        <Row className="flex wrap h-100 align-items-center">
                            <Col md="4" xs="5">
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                    <span className="avatar-title">
                                        <i className={getIconClass(title) + " font-size-24"} />
                                    </span>
                                </div>
                            </Col>
                            <Col md="8" xs="7">
                                <div className="text-center">
                                    <p className="h6 text-muted">{title}</p>
                                    {isCaixaGeral ? (
                                        <h6 className="font-weight-bold">Total: {formatCurrency(totalValue || 0)}</h6>
                                    ) : (
                                        <>
                                            <h6 className="font-weight-bold">Entrada: {formatCurrency(data.entrada || 0)}</h6>
                                            <h6 className="font-weight-bold">Saída: {formatCurrency(data.saida || 0)}</h6>
                                            <h6 className="font-weight-bold">Em Caixa: {formatCurrency(totalValue || 0)}</h6>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    };

    const renderTotalBadge = () => {
        if (!hasBankTransferPermission) return null;

        return (
            <div className="d-flex justify-content-end" style={{ width: "100%" }}>
                <Card
                    className="mini-stats-wid shadow-sm"
                    style={{
                        height: "auto",
                        width: "100%",
                        maxWidth: "250px",
                        borderRadius: "18px",
                        backgroundColor: "#50a5f1",
                        color: "white",
                    }}
                >
                    <CardBody className="h-100 d-flex flex-column justify-content-center align-items-center">
                        <Row className="h-100 align-items-center">
                            <Col md="4" xs="5">
                                <div className="avatar-sm rounded-circle align-self-center">
                                    <span className="avatar-title text-white">
                                        <i className={`${getIconClass("Caixa Geral")} font-size-24`} />
                                    </span>
                                </div>
                            </Col>
                            <Col md="8" xs="7">
                                <div className="text-center">
                                    <p className="h6 font-weight-bold mb-2 font-size-18" style={{ color: "white" }}>
                                        Caixa Geral
                                    </p>
                                    <h6 className="font-weight-bold font-size-18" style={{ color: "white" }}>
                                        {formatCurrency(currentSummary.Geral.total || 0)}
                                    </h6>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    };

    return (
        <div>
            {/* Badge Total */}
            {renderTotalBadge()}

            {isCashOpen ? 
                <Row className="mt-3">
                {renderBICard("PIX", currentSummary.PIX)}
                {renderBICard("Dinheiro", currentSummary.Dinheiro)}
                {renderBICard("Cartão", currentSummary.Cartao)}
                {renderBICard("Total", currentSummary.Total)}
            </Row>
            : <></>}
            
        </div>
    );
};

export default CashControlDashboard;
